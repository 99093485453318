import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from "@ionic/angular";
import {RestService} from "../rest.service";
import {AlertInput} from '@ionic/core/dist/types/components/alert/alert-interface';

@Component({
  selector: 'app-box-detail-modal',
  templateUrl: './box-detail-modal.component.html',
  styleUrls: ['./box-detail-modal.component.scss'],
})
export class BoxDetailModalComponent implements OnInit {

  boxDetail = {
    "name": "",
    "description": "",
    "length": 0,
    "width": 0,
    "height": 0,
    "area": null,
    "pk": ""
  }

  isPatching: boolean = true;

  @Input() id: string;

  constructor(
    public modalController: ModalController,
    public rest: RestService,
    public alertController: AlertController
  ) { }

  ngOnInit() {
    console.log("launch box detail")
    this.rest.get('box/'+this.id)
      .subscribe(
        (res) => {
          console.log(res);
          this.boxDetail = res;
          this.boxDetail.pk = this.id;
          this.isPatching = false;
        }
      )
  }

  calculateSize() {
    if(this.boxDetail.length > 0 && this.boxDetail.width > 0 && this.boxDetail.height > 0){
      return (this.boxDetail.length * this.boxDetail.width * this.boxDetail.height) + " in3"
    }
    return "";
  }

  postChange() {
    this.isPatching = true;
    this.rest.patch('box/'+this.boxDetail.pk+'/', this.boxDetail)
      .subscribe(
        (res) => {
          this.isPatching = false;
        }
      )
  }

  async showTemplateDimensions() {

    let selectedDimensions = {l: 0, w: 0, h: 0};

    let inputs = [
      {
        name: 'gfh',
        type: 'radio',
        label: 'HDX Grey Flip Top',
        value: 'gfh',
        handler: () => {
          selectedDimensions = {l: 22,w: 16,h: 13};
        },
        checked: false
      },
      {
        name: 'hdx-27gal',
        type: 'radio',
        label: 'HDX Yellow 27gal',
        value: 'hdx-27gal',
        handler: () => {
          selectedDimensions = {l: 29,w: 20,h: 16};
        },
        checked: false
      },
      {
        name: 'bankers',
        type: 'radio',
        label: 'Bankers Box 743',
        value: 'bankers',
        handler: () => {
          selectedDimensions = {l: 13,w: 17,h: 9};
        },
        checked: false
      },
    ] as AlertInput[];

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Select a Box',
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (o) => {
            console.log('Confirm Ok');
            this.boxDetail.length = selectedDimensions.l;
            this.boxDetail.width = selectedDimensions.w;
            this.boxDetail.height = selectedDimensions.h;
            this.postChange();
            alert.dismiss();
          }
        }
      ]
    });

    await alert.present();
  }

  doPrintTag() {
    this.rest.get('print/'+this.boxDetail.pk+'/')
      .subscribe(
        (res) => {
          console.log(res)
        },
        (err) => {
          console.log(err)
        }
      )
  }
}
