import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from "@angular/common";
import {PopoverAreaSelectComponent} from "./popover-area-select/popover-area-select.component";
import {NewBoxModalComponent} from "./new-box-modal/new-box-modal.component";
import {FormsModule} from "@angular/forms";
import {ItemDetailModalComponent} from "./item-detail-modal/item-detail-modal.component";
import {OutdatedVerDropComponent} from "./outdated-ver-drop/outdated-ver-drop.component";
import {BoxDetailModalComponent} from "./box-detail-modal/box-detail-modal.component";

@NgModule({
  declarations: [AppComponent, PopoverAreaSelectComponent, NewBoxModalComponent, ItemDetailModalComponent, OutdatedVerDropComponent, BoxDetailModalComponent],
  entryComponents: [],
  imports: [BrowserModule, CommonModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
