import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from "@ionic/angular";
import {RestService} from "../rest.service";

@Component({
  selector: 'app-item-detail-modal',
  templateUrl: './item-detail-modal.component.html',
  styleUrls: ['./item-detail-modal.component.scss'],
})
export class ItemDetailModalComponent implements OnInit {

  itemDetail = {
    "name": "",
    "description": "",
    "amount": 0,
    "length": 0,
    "width": 0,
    "height": 0,
    "box": null,
    "area": null,
    "pk": ""
  }

  isPatching: boolean = true;

  @Input() id: string;


  constructor(
    public modalController: ModalController,
    public rest: RestService,
    public alertController: AlertController
  ) { }

  ngOnInit() {
    this.isPatching = true;
    this.rest.get('item/'+this.id)
      .subscribe(
        (res) => {
          console.log(res);
          this.itemDetail = res;
          this.itemDetail.pk = this.id;
          this.isPatching = false;
        }
      )
  }

  calculateSize() {
    if(this.itemDetail.length > 0 && this.itemDetail.width > 0 && this.itemDetail.height > 0){
      return (this.itemDetail.length * this.itemDetail.width * this.itemDetail.height) + " in3"
    }
    return "";
  }

  postChange() {
    this.isPatching = true;
    this.rest.patch('item/'+this.itemDetail.pk+'/', this.itemDetail)
      .subscribe(
        (res) => {
          this.isPatching = false;
        }
      )
  }

  doMovePopover() {

  }

  doDelete() {
    this.rest.delete('item/'+this.itemDetail.pk+'/')
      .subscribe(
        (res) => {
          this.modalController.dismiss();
        }
      )
  }

  unboxItem() {
    this.itemDetail.area = this.rest.selectedArea.pk;
    this.itemDetail.box = null;
    this.postChange();
    this.ngOnInit();
  }

  setItemInThisBox(pk: string){
    this.itemDetail.area = null;
    this.itemDetail.box = pk;
    this.postChange();
    this.ngOnInit();
  }

  async placeItemInABox() {

    let inputs = [];

    let selectedKey = ""

    await this.rest.get('box/?area='+this.rest.selectedArea.pk)
      .subscribe(
        async (res) => {
          res.forEach((o) => {
            inputs.push({
              name: o.pk,
              type: 'radio',
              label: o.name,
              value: o.pk,
              handler: () => {
                selectedKey = o.pk;
              },
              checked: false
            })
          });


          const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Select a Box',
            inputs: inputs,
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel');
                }
              }, {
                text: 'Ok',
                handler: (o) => {
                  console.log('Confirm Ok');
                  //console.log(o);
                  //console.log(selectedKey);
                  this.setItemInThisBox(selectedKey);
                  alert.dismiss();
                }
              }
            ]
          });

          await alert.present();


        }
      )



  }

  doPrintTag() {
    this.rest.get('print/'+this.itemDetail.pk+'/')
      .subscribe(
        (res) => {
          console.log(res)
        },
        (err) => {
          console.log(err)
        }
      )
  }
}
