import { Component, OnInit } from '@angular/core';
import {RestService} from "../rest.service";
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-popover-area-select',
  templateUrl: './popover-area-select.component.html',
  styleUrls: ['./popover-area-select.component.scss'],
})
export class PopoverAreaSelectComponent implements OnInit {

  constructor(
    public rest: RestService,
    public popoverController: PopoverController
  ) { }

  ngOnInit() {
    const x = this.rest.get('area/')
      .subscribe(
        (res) => {
          this.rest.areas = res;
          this.rest.selectedArea = this.rest.areas[0];
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  selectArea(a: any) {
    this.rest.selectedArea = a;
    this.rest.constructBoxes(a);
  }
}
