import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../environments/environment";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RestService {

  public areas = [];
  public selectedArea: any;

  public isUnboxed: boolean = false;

  public appPages = [

  ];

  authToken = "634aa52254c3cdf2752e8e567bd4e0091fd00363";

  options = {
    headers: {"Authorization": "Token "+this.authToken}
  }

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
  }

  constructBoxes(selectedArea: any) {
    const y = this.get('box/?area='+selectedArea.pk)
      .subscribe(
        (res) => {
          console.log(res);
          let n = [];
          for (let i = 0; i < res.length; i++) {
              n.push(
                {title: res[i].name, url: '/box/page/'+res[i].pk, icon: 'cube'}
              );
          }
          this.appPages = n;
        }
      );
  }

  post(uri: string, data: any): Observable<any>{
    return this.http.post(environment.baseUrl+uri, data, this.options);
  }
  patch(uri: string, data: any): Observable<any>{
    return this.http.patch(environment.baseUrl+uri, data, this.options);
  }
  get(uri: string): Observable<any>{
    return this.http.get(environment.baseUrl+uri, this.options);
  }
  delete(uri: string): Observable<any>{
    return this.http.delete(environment.baseUrl+uri, this.options);
  }
}
