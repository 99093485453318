import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {FrontComponent} from "./front/front.component";
import {OutdatedVerDropComponent} from "./outdated-ver-drop/outdated-ver-drop.component";

const routes: Routes = [
  {
    path: '',
    component: FrontComponent
  },
  {
    path: 'box',
    loadChildren: () => import('./boxes/boxes.module').then( m => m.BoxesPageModule)
  },
  {
    path: 'unboxed',
    loadChildren: () => import('./boxes/boxes.module').then( m => m.BoxesPageModule)
  },
  {
    path: 'outdated',
    component: OutdatedVerDropComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
