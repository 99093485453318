import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {RestService} from "../rest.service";

@Component({
  selector: 'app-new-box-modal',
  templateUrl: './new-box-modal.component.html',
  styleUrls: ['./new-box-modal.component.scss'],
})
export class NewBoxModalComponent implements OnInit {
  newBox = {
    name: '',
    description: ''
  };

  constructor(
    public modalController: ModalController,
    public rest: RestService
  ) { }

  ngOnInit() {}

  createNewBox() {
    let fragment = {
      name: this.newBox.name,
      description: this.newBox.description,
      area: this.rest.selectedArea.pk
    }
    this.rest.post('box/', fragment)
      .subscribe(
        (res) => {
          this.rest.constructBoxes(this.rest.selectedArea);
          this.rest.router.navigate(['/box/page/'+res.pk])
          this.modalController.dismiss()
        }
      )
  }
}
