import {Component, OnInit} from '@angular/core';
import {RestService} from "./rest.service";
import {ModalController, PopoverController} from "@ionic/angular";
import {PopoverAreaSelectComponent} from "./popover-area-select/popover-area-select.component";
import {NewBoxModalComponent} from "./new-box-modal/new-box-modal.component";
import {Router} from "@angular/router";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    public rest: RestService,
    public popoverController: PopoverController,
    public modalController: ModalController,
    public router: Router
  ) {}

  ngOnInit() {

    this.rest.get('version/')
      .subscribe(
        (res) => {
          if(res.version !== "v1.3.0"){
            this.router.navigate(['/outdated'])
          }
        }
      )

    const x = this.rest.get('area/')
      .subscribe(
        (res) => {
          this.rest.areas = res;
          this.rest.selectedArea = this.rest.areas[0];
          this.rest.constructBoxes(this.rest.selectedArea);
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  async presentNewBoxModal() {
    const modal = await this.modalController.create({
      component: NewBoxModalComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverAreaSelectComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  urlUnboxedItems(selectedArea: any) {
    this.rest.isUnboxed = true;
    return '/unboxed/page/'+selectedArea.pk
  }
}
