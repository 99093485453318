import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outdated-ver-drop',
  templateUrl: './outdated-ver-drop.component.html',
  styleUrls: ['./outdated-ver-drop.component.scss'],
})
export class OutdatedVerDropComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
